<template>
  <div class="row">
    <div class="col-xl-12">
      <div id="panel-1" class="panel">
        <div class="panel-container show">
          <div class="panel-content">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="5">
                <el-date-picker class="w-100 mt-3"
                                v-model="form.date"
                                :format="view"
                                :picker-options="pickerOptions"
                                value-format="yyyy-MM-dd"
                                @change="changeDate"
                                placeholder="Chọn ngày">
                </el-date-picker>
              </el-col>

              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="5" v-if="showSelectLichHen">
                <el-select v-model="form.self" clearable class="w-100 mt-3" placeholder="Chọn lịch" default-first-option
                           @change="reloadModel">
                  <el-option
                      v-for="item in optionSelf"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id">
                    <span style="float: left">{{ item.value }}</span>
                  </el-option>
                </el-select>
              </el-col>

            </el-row>
            <div class="mb-5 mt-5" v-if="form.date">
            </div>
            <!-- datatable start -->
            <el-empty v-if="!form.date" description="Bạn chưa chọn ngày"></el-empty>
            <el-row v-else v-loading="loading.loadingCalendar" class="table-responsive-lg">
              <table class="table table-bordered table-hover m-0 text-center" id="tvv-table">
                <thead>
                <tr>
                  <th class="action-date">
                    <el-popover placement="right" width="200" v-model="visible.pre">
                      <p class="text-break"><i class="el-icon-warning mr-2 text-warning"></i>Bạn vẫn muốn tiếp tục khi
                        chưa lưu thông tin?</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="visible.pre = false">Hủy</el-button>
                        <el-button type="primary" size="mini" @click="visible.pre = false; preDate()">Tiếp tục
                        </el-button>
                      </div>
                      <!--eslint-disable-->
                      <i slot="reference" @click="checkVisible(false)" class="el-icon-arrow-left"></i>
                    </el-popover>
                  </th>
                  <th class="text-center" v-for="(item, key) in table.columns" :key="key" colspan="8">
                    <span class="text-capitalize">{{ item.dayOfWeek }}</span> <br> {{ item.day }}
                  </th>
                  <th class="action-date">
                    <el-popover placement="right" width="200" v-model="visible.next">
                      <p class="text-break"><i class="el-icon-warning mr-2 text-warning"></i>Bạn vẫn muốn tiếp tục khi
                        chưa lưu thông tin?</p>
                      <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="visible.next = false">Hủy</el-button>
                        <el-button type="primary" size="mini" @click="visible.next = false; nextDate()">Tiếp tục
                        </el-button>
                      </div>
                      <!--eslint-disable-->
                      <i slot="reference" @click="checkVisible(true)" class="el-icon-arrow-right"></i>
                    </el-popover>
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th class="vertical-center" >Buổi</th>
                  <th class="vertical-center" >Thời gian</th>
<!--                  <th class="vertical-center" rowspan="2">Tư vấn viên</th>-->
                  <th class="vertical-center" >Địa điểm tư vấn</th>
                  <th class="vertical-center" >Hình thức tư vấn</th>
                  <th class="vertical-center" >Khách hàng</th>
                  <th class="vertical-center" >Người giới thiệu</th>
                  <th style="width: 12%" class="vertical-center">Hành động</th>
                </tr>


                <tr v-if="tableData['morning'] && tableData['morning'].length === 0 ">
                  <th scope="row" class="session top"> Buổi Sáng</th>
                  <td colspan="9" class="vertical-center"> Chưa có lịch</td>
                </tr>
                <tr v-else v-for="(item, key) in tableData['morning']" :key="'M' + key"
                    :class="{'background-yellow': user.id === Number(item.nguoi_tu_van)}">
                  <td v-if="key === 0" :rowspan="tableData['morning'].length"
                      style="vertical-align : middle;text-align:center;" class="background-white"> Buổi sáng
                  </td>
                  <td> {{ item.ngay_hen }}</td>
<!--                  <td> {{ item.ten_nguoi_tu_van }}</td>-->
                  <td> {{ item.dia_diem_tu_van }}</td>
                  <td> {{ HINH_THUC_TU_VAN[item.hinh_thuc_tu_van] }}</td>
                  <td> {{ item.ten_kh }}-{{ item.sdt_kh }}</td>
                  <td> {{ item.ten_nguoi_gioi_thieu }} - {{ item.tvv_phone }} - {{ item.ten_van_phong }} </td>
                  <td class="tvv-action vertical-center" v-if="user.id== item.nguoi_tu_van">
                    <div v-if="LEVEL_CONSULTED.includes(item.level)" style="width: max-content">
                      <button class="btn btn-success btn-tuvan  mr-1 ml-1 mb-1" disabled><i
                          class="el-icon-finished mr-2"></i>Đã tư vấn
                      </button>
                      <a @click="handleTestInputSchedule(item)"
                         :title="item.test_input_schedule_id ? 'Ca test đầu vào' : 'Đặt lịch test đầu vào '"
                         :class="{'btn-danger': item.test_input_schedule_id , 'btn-secondary': !item.test_input_schedule_id}"
                         class="btn bg-olive btn-tuvan view_customer  mr-1 ml-1 mb-1 pointer">
                        <svg v-if="item.test_input_schedule_id" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-range-fill" viewBox="0 0 16 16">
                          <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 7V5H0v5h5a1 1 0 1 1 0 2H0v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9h-6a1 1 0 1 1 0-2h6z"/>
                        </svg>
                        <i v-else class="fal fa-book text-black" aria-hidden="true"></i>
                      </a>
                      <router-link
                          class="btn btn-primary btn-tuvan editCustomer  mr-1 ml-1 mb-1"
                          :to="{ name: 'list-customer-tvv', query : {phone :item.sdt_kh } }"
                          style="text-decoration: unset"
                          title="Danh sách khách hàng tư vấn"
                      >
                        <i class="fa fa-eye"></i>
                      </router-link>
                    </div>
                    <div v-else>
                      <router-link
                          v-if="item.khach_hang_den"
                          class="btn btn-danger btn-tuvan editCustomer  mr-1 ml-1 mb-1"
                          :to="{ path: 'advise/center', query : {customer_id :item.id } }"
                          style="text-decoration: unset"
                          title="Trang tư vấn"
                      >
                        <i class="fa fa-home"></i>
                      </router-link>
                      <a class="btn btn-success btn-tuvan khach_hang_den  mr-1 ml-1 mb-1" data-id=""
                         title="Xác nhận khách hàng đến" v-else @click="khachHangDen(item.id)"
                      >
                        <i class="fa fa-check" aria-hidden="true"></i>
                      </a>
                      <CustomerDecision v-if="item.khach_hang_den" :customer="item"></CustomerDecision>
                      <a class="btn btn-info btn-tuvan switchtvv  mr-1 ml-1 mb-1" data-id="" title="Chuyển TVV"
                         @click="ChangeTVV(item.id)" v-if="!item.khach_hang_den">
                        <i class="fa fa-fighter-jet" aria-hidden="true"></i>
                      </a>
                      <a @click="handleTestInputSchedule(item)"
                         :title="item.test_input_schedule_id ? 'Ca test đầu vào' : 'Đặt lịch test đầu vào '"
                         :class="{'btn-danger': item.test_input_schedule_id , 'btn-secondary': !item.test_input_schedule_id}"
                         class="btn bg-olive btn-tuvan view_customer  mr-1 ml-1 mb-1 pointer">
                        <svg v-if="item.test_input_schedule_id" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-range-fill" viewBox="0 0 16 16">
                          <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 7V5H0v5h5a1 1 0 1 1 0 2H0v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9h-6a1 1 0 1 1 0-2h6z"/>
                        </svg>
                        <i v-else class="fal fa-book text-black" aria-hidden="true"></i>
                      </a>
                      <a title="Xem chi tiết" class="btn btn-info bg-olive btn-tuvan view_customer  mr-1 ml-1 mb-1"
                         data-id="" :href="'customer/edit/' + item.id"><i class="fa fa-eye" aria-hidden="true"></i></a>
                    </div>
                  </td>
                </tr>


                <tr v-if="tableData['afternoon'] && tableData['afternoon'].length === 0 ">
                  <th scope="row" class="session top"> Buổi Chiều</th>
                  <td colspan="9" class="vertical-center"> Chưa có lịch</td>
                </tr>
                <tr v-else v-for="(item, key) in tableData['afternoon']" :key="'A' + key"
                    :class="{'background-yellow': user.id === Number(item.nguoi_tu_van)}">
                  <td v-if="key === 0" :rowspan="tableData['afternoon'].length"
                      style="vertical-align : middle;text-align:center;" class="background-white"> Buổi chiều
                  </td>
                  <td> {{ item.ngay_hen }}</td>
<!--                  <td> {{ item.ten_nguoi_tu_van }}</td>-->
                  <td> {{ item.dia_diem_tu_van }}</td>
                  <td> {{ item.hinh_thuc_tu_van == 1 ? 'Tư vấn qua Edutalk' : 'Tư vấn không qua Edutalk' }}</td>
                  <td> {{ item.ten_kh }} - {{ item.sdt_kh }}</td>
                  <td> {{ item.ten_nguoi_gioi_thieu }} - {{ item.tvv_phone }} - {{ item.ten_van_phong }} </td>
                  <td class="tvv-action vertical-center" v-if="user.id== item.nguoi_tu_van">
                    <div v-if="LEVEL_CONSULTED.includes(item.level)" style="width: max-content">
                      <button class="btn btn-success btn-tuvan  mr-1 ml-1 mb-1" disabled><i
                          class="el-icon-finished mr-2"></i>Đã tư vấn
                      </button>
                      <a @click="handleTestInputSchedule(item)"
                         :title="item.test_input_schedule_id ? 'Ca test đầu vào' : 'Đặt lịch test đầu vào '"
                         :class="{'btn-danger': item.test_input_schedule_id , 'btn-secondary': !item.test_input_schedule_id}"
                         class="btn bg-olive btn-tuvan view_customer  mr-1 ml-1 mb-1 pointer">
                        <svg v-if="item.test_input_schedule_id" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-range-fill" viewBox="0 0 16 16">
                          <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 7V5H0v5h5a1 1 0 1 1 0 2H0v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9h-6a1 1 0 1 1 0-2h6z"/>
                        </svg>
                        <i v-else class="fal fa-book text-black" aria-hidden="true"></i>
                      </a>
                      <router-link
                          class="btn btn-primary btn-tuvan editCustomer  mr-1 ml-1 mb-1"
                          :to="{ name: 'list-customer-tvv', query : {phone :item.sdt_kh } }"
                          style="text-decoration: unset"
                          title="Danh sách khách hàng tư vấn"
                      >
                        <i class="fa fa-eye"></i>
                      </router-link>
                    </div>
                    <div v-else>
                      <router-link
                          v-if="item.khach_hang_den"
                          class="btn btn-danger btn-tuvan editCustomer  mr-1 ml-1 mb-1"
                          :to="{ path: 'advise/center', query : {customer_id :item.id } }"
                          style="text-decoration: unset"
                          title="Trang tư vấn"
                      >
                        <i class="el-icon-s-home"></i>
                      </router-link>
                      <a class="btn btn-success btn-tuvan khach_hang_den  mr-1 ml-1 mb-1 pointer" data-id=""
                         title="Xác nhận khách hàng đến" v-else @click="khachHangDen(item.id)"
                         :style="disableButton ? 'pointer-events: none' : 'pointer-events: unset'"
                      >
                        <i class="fa fa-check check_customer" aria-hidden="true"></i>
                      </a>
                      <CustomerDecision v-if="item.khach_hang_den" :customer="item"></CustomerDecision>
                      <a class="btn btn-info btn-tuvan switchtvv  mr-1 ml-1 mb-1 pointer" data-id="" title="Chuyển TVV"
                         @click="ChangeTVV(item.id)" v-if="!item.khach_hang_den">
                        <i class="fa fa-fighter-jet" aria-hidden="true"></i>
                      </a>
                      <a @click="handleTestInputSchedule(item)"
                         :title="item.test_input_schedule_id ? 'Ca test đầu vào' : 'Đặt lịch test đầu vào '"
                         :class="{'btn-danger': item.test_input_schedule_id , 'btn-secondary': !item.test_input_schedule_id}"
                         class="btn bg-olive btn-tuvan view_customer  mr-1 ml-1 mb-1 pointer">
                        <svg v-if="item.test_input_schedule_id" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-range-fill" viewBox="0 0 16 16">
                          <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 7V5H0v5h5a1 1 0 1 1 0 2H0v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9h-6a1 1 0 1 1 0-2h6z"/>
                        </svg>
                        <i v-else class="fal fa-book text-black" aria-hidden="true"></i>
                      </a>
                      <a title="Xem chi tiết" class="btn btn-info bg-olive btn-tuvan view_customer  mr-1 ml-1 mb-1"
                         data-id="" :href="'customer/edit/' + item.id"><i class="fa fa-eye" aria-hidden="true"></i></a>
                    </div>

                  </td>
                </tr>


                <tr v-if="tableData['evening'] && tableData['evening'].length === 0 ">
                  <th scope="row" class="session top"> Buổi Tối</th>
                  <td colspan="9" class="vertical-center"> Chưa có lịch</td>
                </tr>
                <tr v-else v-for="(item, key) in tableData['evening']" :key="'E' + key"
                    :class="{'background-yellow': user.id === Number(item.nguoi_tu_van)}">
                  <td v-if="key === 0" :rowspan="tableData['evening'].length"
                      style="vertical-align : middle;text-align:center;" class="background-white"> Buổi tối
                  </td>
                  <td> {{ item.ngay_hen }}</td>
<!--                  <td> {{ item.ten_nguoi_tu_van }}</td>-->
                  <td> {{ item.dia_diem_tu_van }}</td>
                  <td> {{ item.hinh_thuc_tu_van == 1 ? 'Tư vấn qua Edutalk' : 'Tư vấn không qua Edutalk' }}</td>
                  <td> {{ item.ten_kh }} - {{ item.sdt_kh }}</td>
                  <td> {{ item.ten_nguoi_gioi_thieu }} - {{ item.tvv_phone }} - {{ item.ten_van_phong }} </td>
                  <td class="tvv-action vertical-center" v-if="user.id== item.nguoi_tu_van">
                    <div v-if="LEVEL_CONSULTED.includes(item.level)" style="width: max-content">
                      <button class="btn btn-success btn-tuvan  mr-1 ml-1 mb-1" disabled><i
                          class="el-icon-finished mr-2"></i>Đã tư vấn
                      </button>
                      <a @click="handleTestInputSchedule(item)"
                         :title="item.test_input_schedule_id ? 'Ca test đầu vào' : 'Đặt lịch test đầu vào '"
                         :class="{'btn-danger': item.test_input_schedule_id , 'btn-secondary': !item.test_input_schedule_id}"
                         class="btn bg-olive btn-tuvan view_customer  mr-1 ml-1 mb-1 pointer">
                        <svg v-if="item.test_input_schedule_id" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-range-fill" viewBox="0 0 16 16">
                          <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 7V5H0v5h5a1 1 0 1 1 0 2H0v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9h-6a1 1 0 1 1 0-2h6z"/>
                        </svg>
                        <i v-else class="fal fa-book text-black" aria-hidden="true"></i>
                      </a>
                      <router-link
                          class="btn btn-primary btn-tuvan editCustomer  mr-1 ml-1 mb-1"
                          :to="{ name: 'list-customer-tvv', query : {phone :item.sdt_kh } }"
                          style="text-decoration: unset"
                          title="Danh sách khách hàng tư vấn"
                      >
                        <i class="fa fa-eye"></i>
                      </router-link>
                    </div>
                    <div v-else>
                      <router-link
                          v-if="item.khach_hang_den"
                          class="btn btn-danger btn-tuvan editCustomer  mr-1 ml-1 mb-1"
                          :to="{ path: 'advise/center', query : {customer_id :item.id } }"
                          style="text-decoration: unset"
                          title="Trang tư vấn"
                      >
                        <i class="el-icon-s-home"></i>
                      </router-link>
                      <a class="btn btn-success btn-tuvan khach_hang_den  mr-1 ml-1 mb-1" data-id=""
                         title="Xác nhận khách hàng đến" v-else @click="khachHangDen(item.id)">
                        <i class="fa fa-check check_customer" aria-hidden="true"></i>
                      </a>
                      <CustomerDecision v-if="item.khach_hang_den" :customer="item"></CustomerDecision>
                      <a class="btn btn-info btn-tuvan switchtvv  mr-1 ml-1 mb-1" data-id="" title="Chuyển TVV"
                         @click="ChangeTVV(item.id)" v-if="!item.khach_hang_den">
                        <i class="fa fa-fighter-jet" aria-hidden="true"></i>
                      </a>
                      <a @click="handleTestInputSchedule(item)"
                         :title="item.test_input_schedule_id ? 'Ca test đầu vào' : 'Đặt lịch test đầu vào '"
                         :class="{'btn-danger': item.test_input_schedule_id , 'btn-secondary': !item.test_input_schedule_id}"
                         class="btn  bg-olive btn-tuvan view_customer  mr-1 ml-1 mb-1 pointer">
                        <svg v-if="item.test_input_schedule_id" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-range-fill" viewBox="0 0 16 16">
                          <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 7V5H0v5h5a1 1 0 1 1 0 2H0v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9h-6a1 1 0 1 1 0-2h6z"/>
                        </svg>
                        <i v-else class="fal fa-book text-black" aria-hidden="true"></i>
                      </a>
                      <a title="Xem chi tiết" class="btn btn-info bg-olive btn-tuvan view_customer  mr-1 ml-1 mb-1"
                         data-id="" :href="'customer/edit/' + item.id"><i class="fa fa-eye" aria-hidden="true"></i></a>
                    </div>

                  </td>
                </tr>
                </tbody>
              </table>
            </el-row>
            <!-- datatable end -->
          </div>
        </div>
      </div>
    </div>

    <el-dialog class="responsive" :visible.sync="dialogNotiNotAcceptCustomerGoTest" :title="dialog.title">
      <el-row :gutter="10" class="pr-2 pl-2">
        <h3>Chưa tới ngày hẹn, không thể xác nhận khách hàng lên văn phòng</h3>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogNotiNotAcceptCustomerGoTest = false">Ok</el-button>
      </div>
    </el-dialog>

    <el-dialog class="responsive" :visible.sync="dialogChangeTVV" title="Chuyển tư vấn viên">
      <el-row>
        <table class="table table-bordered m-0" id="tvv-table">
          <thead>
          <tr>
            <th class="vertical-center" colspan="2"> Danh sách tư vấn viên</th>
          </tr>
          <tr>
            <th class="vertical-center"> Tư vấn viên</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, key) in tvvs" :key="key">
            <th> {{ item.name }}</th>
            <th>
              <a class="btn btn-info btn-tuvan" data-id="" title="Chuyển TVV" style="margin-right: 10px"
                 @click="postChangeTVV(item.id)">
                Chuyển
              </a>
            </th>
          </tr>
          </tbody>
        </table>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogChangeTVV = false">Ok</el-button>
      </div>
    </el-dialog>
    <el-dialog title="Đặt lịch test đầu vào" class="responsive" :visible.sync="dialogFormTestInputVisible" :destroy-on-close="true"
               :before-close="statusCreateTestInput=STATUS_CHOOSE_OPTION" :close-on-click-modal="false"
               :show-close="false">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="form appointment-form" @submit.prevent="handleSubmit(createTestInputSchedule)" autocomplete="off">
          <el-row :gutter="10" class="pr-2 pl-2">
            <option-create-test-input v-if="statusCreateTestInput === STATUS_CHOOSE_OPTION" @confirmOption="confirmOption" @cancel="dialogFormTestInputVisible = false"></option-create-test-input>
            <test-input v-if="statusCreateTestInput === STATUS_CREATE" :checkExist="false" @infoTestInput="handleInfoTestInput"></test-input>
            <LoadingCreateTestInput v-if="statusCreateTestInput === STATUS_PENDING" :idTaskCreating = "idTaskCreating" @statusCreateTestInput="handleStatusCreateTestInput"></LoadingCreateTestInput>
            <succes-create-input v-if="statusCreateTestInput === STATUS_TO_DO" @closeModal="dialogFormTestInputVisible = false" @reloadPage=" reloadModel();" :idCustomer="idCustomer" :successCreateData="successCreateData"></succes-create-input>
            <cancel-create-input v-if="statusCreateTestInput === STATUS_FAlURE" @closeModal="dialogFormTestInputVisible = false"></cancel-create-input>
            <overload-create-test-input v-if="statusCreateTestInput === STATUS_OVER_LOADING" @closeModal="dialogFormTestInputVisible = false"></overload-create-test-input>
          </el-row>
          <div class="form-row" v-if="statusCreateTestInput === STATUS_CREATE">
            <div class="col-md-12 mb-3">
              <button class="btn btn-primary float-right mt-3 ml-3" :disabled="checkClick">
                Xác nhận
              </button>
              <a @click="closeDialogTestInputSchedule" class="btn btn-secondary float-right mt-3 ml-3 text-white pointer">
                Hủy bỏ
              </a>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </el-dialog>
  </div>
</template>


<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {
  GET_SWITCH_TVV,
  POST_SWITCH_TVV,
  SCHEDULE_CONSULTANT,
  SCHEDULE_CONSULTANT_KH_DEN
} from "@/core/services/store/service.module";
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import {mapGetters} from "vuex";
import {
  GDVP
} from "@/core/config/accountTypeOption";
import CustomerDecision from "@/views/pages/work/components/CustomerDecision";
import {HINH_THUC_TU_VAN, LEVEL_CONSULTED} from "@/core/config/customer/customerOption";
import TestInput from "@/views/pages/customer/test-input";
import {CREATE_TEST_INPUT_SCHEDULE} from "@/core/services/store/customer/testInput.module";
import CancelCreateInput from "../customer/components/CancelCreateInput.vue";
import LoadingCreateTestInput from "../customer/components/LoadingCreateTestInput.vue";
import SuccesCreateInput from "../customer/components/SuccesCreateInput.vue";
import OverloadCreateTestInput from "../customer/components/OverloadCreateTestInput.vue";
import {
  STATUS_CHOOSE_OPTION,
  STATUS_CREATE,
  STATUS_FAlURE,
  STATUS_OVER_LOADING,
  STATUS_PENDING,
  STATUS_TO_DO
} from "../../../core/config/testInput/testInputOption";
import OptionCreateTestInput from "../customer/components/OptionCreateTestInput.vue";
import {STORE_ONLY_GENERAL} from "../../../core/services/store/customer/testInput.module";
import router from "../../../router";

const _ = deepdash(lodash);

export default {
  name: "EdutalkLichTuVAn",
  components: {
    OptionCreateTestInput,
    OverloadCreateTestInput,
    SuccesCreateInput,
    LoadingCreateTestInput,
    CancelCreateInput,
    TestInput, CustomerDecision},
  data() {
    return {
      LEVEL_CONSULTED: LEVEL_CONSULTED,
      HINH_THUC_TU_VAN: HINH_THUC_TU_VAN,
      dialogVisible: false,
      loading: {
        loadingCalendar: false,
      },
      fullScreen: {
        isFullCalendar: false
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      form: {
        date: null,
        start: null,
        end: null,
        self: null
      },
      table: {
        columns: [],
      },
      current: null,
      morning: [],
      afternoon: [],
      evening: [],
      morningDisable: [],
      afternoonDisable: [],
      eveningDisable: [],
      view: null,
      visible: {
        pre: null,
        next: null
      },
      transform: {
        morning: 'Buổi sáng',
        afternoon: 'Buổi chiều',
        evening: 'Buổi tối',
      },
      tableData: [],
      getData: null,
      dialogNotiNotAcceptCustomerGoTest: false,
      dialogChangeTVV: false,
      tvvs: [],
      id_customer_change: 0,
      dialog: {
        title: null
      },
      startTime: '',
      endTime: '',
      optionSelf: [
        {id: '1', value: 'Lịch theo đội'},
        {id: '2', value: 'Lịch theo điểm'},
      ],
      user: [],
      showSelectLichHen: false,
      disableButton: false,
      dialogFormTestInputVisible: false,
      customerSelectId: 0,
      testInput: {},
      checkClick : false,
      statusCreateTestInput: STATUS_CHOOSE_OPTION,
      idTaskCreating : null,
      STATUS_TO_DO:STATUS_TO_DO,
      STATUS_PENDING:STATUS_PENDING,
      STATUS_FAlURE:STATUS_FAlURE,
      STATUS_OVER_LOADING:STATUS_OVER_LOADING,
      STATUS_CREATE:STATUS_CREATE,
      idCustomer : null,
      selectedCustomer: null,
      STATUS_CHOOSE_OPTION: STATUS_CHOOSE_OPTION,
      successCreateData: {}
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Lịch tư vấn", icon: 'far fa-calendar-alt'}
    ]);
    this.current = this.$moment().format('YYYY-MM-DD');
    this.form.date = this.$route.query.view_date ? this.$route.query.view_date : this.$moment().format('YYYY-MM-DD');
    this.convertViewDate();
    if (this.currentUser.account_type.id === GDVP) {
        this.showSelectLichHen = true
    }
    let userPositions = this.currentUser.user_positions;
    let isTVV = _.filter(userPositions, function(p) {
        return p.position === 'TPOnline';
    });
    if (isTVV.length > 0) {
      this.showSelectLichHen = true
    }
  },
  methods: {
    handleCompleted() {
      this.dialogFormAddVisible = false;
      this.reloadModel();
    },
    renderDate(data) {
      return this.$moment(Date.parse(data.start_date)).format('HH:mm') + ' - ' + this.$moment(Date.parse(data.end_date)).format('HH:mm') + ' - ' + data.created_name;
    },
    changeDate() {
      this.table.columns = [];
      this.convertViewDate();
    },
    convertViewDate() {
      let begin = this.$moment(this.form.date, 'YYYY-MM-DD');
      this.form.start = begin.format('YYYY-MM-DD');
      this.view = (this.form.date);
      this.table.columns.push({'dayOfWeek': begin.format('dddd'), 'day': begin.format('YYYY-MM-DD'), 'times': []});
      this.reloadModel();
    },
    checkVisible(isNext) {
      this.visible.next = true;
      this.visible.pre = true;
      if (isNext) {
        this.nextDate();
      } else {
        this.preDate();
      }
    },
    nextDate() {
      this.loading.loadingCalendar = true;
      this.table.columns = [];
      let nextDate = this.$moment(this.form.date, 'YYYY-MM-DD').add(1, 'days');
      this.form.date = nextDate.format('YYYY-MM-DD');
      this.convertViewDate();

    },
    preDate() {
      this.loading.loadingCalendar = true;
      this.table.columns = [];
      let preDate = this.$moment(this.form.date, 'YYYY-MM-DD').subtract(1, 'days');
      this.form.date = preDate.format('YYYY-MM-DD');
      this.convertViewDate();
    },
    reloadModel: function () {
      this.clearSession();
      let param = this.mergeParams();
      this.loading.loadingCalendar = true;
      this.$store.dispatch(SCHEDULE_CONSULTANT, param)
          .then((response) => {
            this.user = response.user;
            this.getData = response;
            this.tableData = response.customer;
            this.loading.loadingCalendar = false;
          });
    },
    clearSession() {
      this.morning = [];
      this.afternoon = [];
      this.evening = [];
      this.morningDisable = [];
      this.afternoonDisable = [];
      this.eveningDisable = [];
    },
    mergeParams(customProperties) {
      let params = {};
      if (this.form.start) {
        params = _.merge(params, {view_date: this.form.start})
      }

      if (this.form.self) {
        params = _.merge(params, {self: this.form.self})
      }
      params = _.merge(params, customProperties);
      return params;
    },
    disableCheckbox(item) {
      return item < this.current || item === this.current
    },
    khachHangDen(id) {
      let today = this.$moment(new Date()).format('YYYY-MM-DD');
      if (today != this.form.date) {
        this.dialogNotiNotAcceptCustomerGoTest = true;
        return false;
      }
      this.$confirm('Bạn có xác nhận khách hàng đến văn phòng nghe tư vấn không?', 'Xác nhận', {
        confirmButtonText: 'Xác nhận',
        cancelButtonText: 'Cancel',
        type: 'Xác nhận khách hàng đến',
        center: true
      }).then(() => {
        this.$store.dispatch(SCHEDULE_CONSULTANT_KH_DEN, {id: id})
            .then((response) => {
              // console.log(" SCHEDULE_CONSULTANT_KH_DEN response", response)
              if(response == 0 ) {
                this.$message({
                  type: 'warning',
                  message: 'Không thể thao tác với khách hàng đã được tư vấn viên khác tư vấn.'
                });
              } else if (response.errors) {
                this.$message({
                  title: 'success',
                  message: `${response.message}`,
                  type: 'warning'
                });
              } else {
                this.dialogFormEditVisible = false;
                this.$message({
                  type: 'success',
                  message: 'Xác nhận thành công.'
                });
                this.reloadModel();
              }
            });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: 'Hủy xác nhận thành công'
        });
      });
    },
    ChangeTVV(id) {
      this.id_customer_change = id,
          this.$store.dispatch(GET_SWITCH_TVV, {id: id, check: 'new'})
              .then((response) => {
                if (response == 0) {
                  this.$message({
                    type: 'warning',
                    message: 'Không thể thao tác với khách hàng đã được tư vấn viên khác tư vấn.'
                  });
                } else if (response.status) {
                  this.dialogChangeTVV = true;
                  this.tvvs = response.data;
                } else {
                  this.$message({
                    type: 'error',
                    message: response.message
                  });
                }

              });
    },
    postChangeTVV(id) {
      this.$store.dispatch(POST_SWITCH_TVV, {customer_id: this.id_customer_change, new_tvv_id: id})
          .then((response) => {
            console.log(response)
            if (response) {
              this.dialogChangeTVV = false;
              this.tvvs = response.data;
              this.$message({
                type: 'info',
                message: "Chuyển tư vấn viên thành công !"
              });
              this.reloadModel();
            } else {
              this.$message({
                type: 'info',
                message: "Có lỗi sảy ra."
              });
            }

          });
    },
    handleTestInputSchedule(item) {
      if (item.test_input_schedule_id) {
        this.$router.push({name: 'customer-test-input-schedule', params: {id: item.id}})
      } else {
        console.log(item);
        this.customerSelectId = item.id
        this.dialogFormTestInputVisible = true
        this.selectedCustomer = item;
      }
    },
    closeDialogTestInputSchedule() {
      this.dialogFormTestInputVisible = false
    },
    handleInfoTestInput(item) {
      this.testInput = item
    },
    createTestInputSchedule() {
      if(this.statusCreateTestInput === this.STATUS_CHOOSE_OPTION){
        return
      }
      this.checkClick = true
      this.testInput = _.merge(this.testInput, { customer_id: this.customerSelectId })
      this.$store.dispatch(CREATE_TEST_INPUT_SCHEDULE, this.testInput).then((res) => {
        if (res.error) {
          this.$message({type: 'error', message: res.error, showClose: true});
        } else {
          setTimeout(()=>{
            this.statusCreateTestInput = STATUS_PENDING;
          })
          this.idTaskCreating = res?.data?.id;
        }
      }).catch((res) => {
        this.$message({type: 'error', message: res?.data?.message ?? "", showClose: true});
      }).finally(()=>{this.checkClick = false});
    },
    handleStatusCreateTestInput(status,customerId,successCreateData){
      this.idCustomer = customerId;
      this.successCreateData = successCreateData
      this.statusCreateTestInput = status
    },
    confirmOption(isOnlyGeneral){
      if(isOnlyGeneral){
        let payload = {
          name: this.selectedCustomer?.ten_kh,
          phone: this.selectedCustomer?.sdt_kh,
          customer_id: this.selectedCustomer?.id
        }
        this.$store.dispatch(STORE_ONLY_GENERAL,payload).then((data)=>{
          router.push({ name: 'customer-test-input-schedule', params: {id: this.selectedCustomer.id}})
        })
        return
      }
      this.statusCreateTestInput = this.STATUS_CREATE;
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  }
};

</script>
<style >
td ,th {
  text-align: center;
  vertical-align: middle !important;
}
.bg-violet {
  background-color: #ae73c7;
}
.pointer {cursor: pointer;}
</style>
